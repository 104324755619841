import React from 'react'

interface IRenderHTML {
  html: string
  title?: string
}

const RenderHTML = ({ html, title }: IRenderHTML) => {
  return (
    <>
      {title && <h1>{title}</h1>}
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </>
  )
}

export default RenderHTML
